@import "../../../sass/main";

.Paypal {
	color: $color-blue;
	font-size: 2.5rem;
	text-align: center;
	display: grid;
	grid-row-gap: 1rem;
	&__button {
		width: 80%;
		max-width: 30rem;
		margin: 1rem auto 0;
	}
	&__paymentFeedback {
		&__title {
			margin-bottom: 1rem;
			font-weight: bold;
		}
		width: 80%;
		margin: 0 auto 8rem;
	}
	&__buy__another {
		@include link;
		margin: 2rem auto;
	}
}
