@import "../../sass/variables";
@import "../../sass/animations";

.list {
	&__list {
		display: grid;
		justify-items: center;
		grid-row-gap: 3rem;
	}

	&__footer {
		@media only screen and(min-width: $phone) {
			grid-template-columns: repeat(2, -webkit-max-content);
			grid-template-columns: repeat(2, max-content);
			-webkit-box-pack: center;
			justify-content: center;
			grid-column-gap: 6rem;
		}
		@media only screen and(min-width: $bp-700) {
			grid-template-columns: repeat(3, -webkit-max-content);
			grid-template-columns: repeat(3, max-content);
		}

		@media only screen and(min-width: $bp-1000) {
			grid-template-columns: repeat(6, -webkit-max-content);
			grid-template-columns: repeat(6, max-content);
		}
		@media only screen and(min-width: $tab-land) {
			font-size: 2.2rem;
			grid-column-gap: 8rem;
		}
	}

	&__nav {
		grid-template-columns: repeat(6, -webkit-max-content);
		grid-template-columns: repeat(6, max-content);
		grid-column-gap: 0.4rem;
		font-size: 2rem;
	}

	&__list_item {
		list-style: none;
		text-align: center;
	}

	&__link {
		padding: 0.5rem 1rem;
		border-radius: 5px;
		color: white;
		&:link,
		&:visited {
			text-decoration: none;
		}

		&__active {
			color: $color-light;
			font-weight: bold;
		}
		&:hover {
			background-color: rgba($color: #fff, $alpha: 0.2);
		}

		&:active {
			background-color: white;
			color: $color-dark;
		}
	}
}
