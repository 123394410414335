@import "../../../../sass/main";

.testimonial {
	display: -webkit-box;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	position: absolute;
	width: 90%;
	@extend %absolute-center;
	z-index: 10;

	padding: 2rem;
	border: 1px solid $color-orange;
	background-color: rgba(255, 255, 255, 0.4);
	box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.8);

	-webkit-transition: all 0.2s;
	transition: all 0.2s;
	text-align: center;
	margin: 0 auto;

	&__image {
		width: 10rem;
		height: 10rem;
		border-radius: 50%;
		-o-object-fit: cover;
		object-fit: cover;
		-webkit-box-flex: 0;
		flex: 0 0 10rem;
		border: 1px solid black;
		box-shadow: 0 0.2rem 0.5rem rgba($color: #000000, $alpha: 0.9);
	}
	&__quote {
		color: $color-blue;
		font-weight: bold;
	}
	&__text {
		font-size: 2rem; //2.8rem;
		margin: 2rem auto 1rem;
		font-style: italic;
	}
	&__student {
		text-transform: uppercase;
		font-size: 1.8rem;
	}
}
