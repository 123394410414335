@import "../../../../sass/main";

.feature {
	@extend %feature-box;
	margin: 5rem auto;
	position: relative;
	-webkit-perspective: 150rem;
	perspective: 150rem;
	padding: 2rem 0 0;
	border: 1px solid $color-light;
	max-width: 32rem;
	@include short-box-shadow;

	@media only screen and(min-width: $tab-port) {
		display: -webkit-box;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		flex-direction: column;
	}

	@media only screen and(hover: hover) {
		padding: 0;
		border: none;
		box-shadow: none;

		&:hover &__side_one {
			-webkit-transform: rotateY(-180deg);
			transform: rotateY(-180deg);
		}

		&:hover > &__side_two {
			-webkit-transform: rotateY(0);
			transform: rotateY(0);
		}
	}

	&__side {
		&_one {
			-webkit-transition: all 0.4s;
			transition: all 0.4s;
			-webkit-backface-visibility: none;
			backface-visibility: hidden;
			-webkit-transform: roateY(0);
			transform: rotateY(0);
			height: 100%;
			margin: 0 4rem;
			@media only screen and(hover: hover) {
				@include feature-box-outline;
				margin: 0 auto;
			}
		}

		&_two {
			border-radius: 0 0 5px 5px;
			background-image: -webkit-gradient(
				linear,
				left top,
				left bottom,
				from(white),
				to($color-light)
			);
			background-image: radial-gradient(
				rgba($color-light, 0.3),
				rgba($color-light, 0.7)
			);
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
			padding: 2rem 0 1rem;
			margin-top: 3rem;

			@media only screen and(hover: hover) {
				@include short-box-shadow;
				border-radius: 5px;
				margin-top: 0;
				padding: 0;
				-webkit-transform: rotateY(180deg);
				transform: rotateY(180deg);
				-webkit-transition: all 0.4s;
				transition: all 0.4s;
				-webkit-backface-visibility: none;
				backface-visibility: hidden;
				position: absolute;
				height: 100%;
				width: 100%;
				top: 0;
				left: 0;
			}
		}
	}

	@media only screen and(min-width: $tab-port) {
		&:not(:last-child) {
			margin-right: 5rem;
		}
	}
	&__icon {
		width: 8rem;
		height: 8rem;
		margin: 1rem auto;
	}
	&__title {
		font-size: 2.8rem;
		margin: 3rem auto 2rem;
	}
	&__text {
		font-size: 2.2rem;
		font-style: italic;
	}

	&__learn_more {
		font-size: 4rem;
		line-height: 1;
		color: $color-blue;
		font-weight: bold;

		cursor: pointer;

		&__text:after {
			// to start at left transform-origin: 0% 50%;
			@include after-underline;
		}

		&:hover &__text:after,
		&:active &__text:after {
			-webkit-transform: scaleX(1);
			transform: scaleX(1);
		}
	}
}
