@import "../../../../sass/main";
.Submitted {
	margin: 5rem 1rem 3rem;
	@media only screen and(min-width: $bp-350) {
		margin: 5rem 1rem 3rem;
	}

	@media only screen and(min-width: $bp-500) {
		margin: 5rem 0.5rem 3rem;
	}
	&__title {
		font-size: 3.5rem;
		color: $color-light;
		text-align: center;
		text-transform: uppercase;

		@media only screen and(min-width: $bp-500) {
			font-size: 3rem;
		}

		@media only screen and(min-width: $tab-port) {
			font-size: 3.5rem;
		}
	}
	&__message {
		margin: 3rem auto;
		@media only screen and(min-width: $bp-1400) {
			width: 80%;
		}

		&__component {
			display: grid;
			grid-template-columns: 5rem 1fr;
			grid-column-gap: 1rem;
			justify-items: start;
			margin: 3rem;
			font-size: 1.8rem;

			@media only screen and(min-width: $bp-400) {
				font-size: 2rem;
			}

			@media only screen and(min-width: $bp-500) {
				font-size: 1.6rem;
				grid-template-columns: 3rem 1fr;
				grid-column-gap: 1rem;
			}
			@media only screen and(min-width: $phone) {
				font-size: 1.8rem;
				grid-template-columns: 3rem 1fr;
				grid-column-gap: 3rem;
			}
			@media only screen and(min-width: $tab-port) {
				font-size: 2rem;
				grid-template-columns: 5rem 1fr;
			}
			@media only screen and(min-width: $tab-land) {
				font-size: 2.3rem;
			}
		}

		&__content {
			color: $color-blue;
		}

		&__header {
			justify-self: end;
			color: $color-light;
			font-weight: bold;
		}
	}

	&__restart {
		@include link;
		text-align: center;
		margin-top: 5rem;
	}

	&__error {
		font-size: 2.5rem;
		text-align: center;
		color: $color-blue;
		margin: 1rem auto;
	}

	@include zoomAnimation;
}
