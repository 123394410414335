$color-orange-light: #f88745;
$color-orange: rgb(203, 126, 75);
$color-orange-dark: #7f2806;
$color-blue-light: #4982c3;
$color-blue: #29507d;
$color-dark: #182f4a;
$color-blue-dark: #294e56;
$color-light: #eb5;
$color-gray: #ccc;

//cb6
//ea4
$apex-distance: calc(100vh * 0.225);

$bp-350: 21.875em;
$bp-400: 25rem;
$bp-500: 31.25em;
$bp-650: 40.625em;
$phone: 37.5em;
$bp-700: 43.75em;
$bp-850: 53.125em;
$tab-port: 56.25em;
$bp-1000: 62.5em;
$bp-1100: 67.75em;
$tab-land: 75em;
$bp-1400: 87.5em;

$font-display: "Josefin Slab", serif;
$font-title: "Philosopher", sans-serif;

$section-height: 90vh;

%clip-path-pointdown {
	-webkit-clip-path: polygon(
		0 0,
		50% calc(100vh * 0.225),
		100% 0,
		100% calc(100% - 100vh * 0.225),
		50% 100%,
		0 calc(100% - 100vh * 0.225)
	);
	clip-path: polygon(
		0 0,
		50% calc(100vh * 0.225),
		100% 0,
		100% calc(100% - 100vh * 0.225),
		50% 100%,
		0 calc(100% - 100vh * 0.225)
	);
}

%clip-path-top-pointup {
	-webkit-clip-path: polygon(
		0 calc(100vh * 0.225),
		50% 0,
		100% calc(100vh * 0.225),
		100% 100%,
		0 100%
	);
	clip-path: polygon(
		0 calc(100vh * 0.225),
		50% 0,
		100% calc(100vh * 0.225),
		100% 100%,
		0 100%
	);
}

%clip-path-bottom-pointdown {
	-webkit-clip-path: polygon(
		0 0,
		0 100%,
		100% calc(100% - 100vh * 0.225),
		50% 100%,
		0 calc(100% - 100vh * 0.225)
	);
	clip-path: polygon(
		0 0,
		100% 0,
		100% calc(100% - 100vh * 0.225),
		50% 100%,
		0 calc(100% - 100vh * 0.225)
	);
}

%clip-path-top-pointdown {
	-webkit-clip-path: polygon(
		0 0,
		50% calc(100vh * 0.225),
		100% 0,
		100% 100%,
		0 100%
	);
	clip-path: polygon(0 0, 50% calc(100vh * 0.225), 100% 0, 100% 100%, 0 100%);
}

%clip-path-hex {
	-webkit-clip-path: polygon(
		0 0,
		50% calc(100vh * 0.225),
		100% 0,
		100% 100%,
		0 100%
	);
	clip-path: polygon(0 0, 50% calc(100vh * 0.225), 100% 0, 100% 100%, 0 100%);
}
