@import "../../../sass/main";

.cta {
	background-image: -webkit-gradient(
			linear,
			left top, right bottom,
			from(rgba($color-light, 0.8)),
			to(rgba($color-light, 0.8))
		),
		url("../../../assets/images/beach-meditation-600.jpg");
	background-image: linear-gradient(
			to right bottom,
			rgba($color-light, 0.8),
			rgba($color-light, 0.8)
		),
		url("../../../assets/images/beach-meditation-600.jpg");
	background-size: cover;
	// background-color: $color-light;
	margin: 0 auto 4rem;
	@media only screen and(min-width: $tab-land) {
		background-position: 0 45%;
	}

	// @extend %clip-path-bottom-pointdown;

	&__learn_more {
		padding: 3rem 3rem calc(100vh * 0.225 + 3rem);
	}

	&__title {
		color: white;
		font-size: 2.8rem;
		margin-bottom: 1rem;
		&__large {
			font-size: 4.5rem;
		}
	}

	&__input {
		@media only screen and(min-width: $tab-port) {
			display: -webkit-box;
			display: flex;
		}
		&__holder {
			width: 100%;
		}

		&__error {
			border: 1px solid red;
		}
	}

	&__name {
		@extend %input;
	}

	&__email {
		@extend %input;
		&:placeholder-shown + &__label {
			opacity: 0;
		}
	}

	&__btn {
		margin: 1.5rem 0 5rem;
	}

	&__register {
		display: grid;
		justify-items: center;
	}

	&__contact {
	}
	@include formError;
}
