@import "../../sass/main";

.PageComponent {
	&__main {
		@media only screen and(min-width: $bp-500) {
			@include short-box-shadow;
			background-color: white;
			width: 70%;
			border: 3px solid $color-light;
			margin-top: -10rem;
			margin-left: 4rem;
			padding: 6rem 4rem 1rem;
			z-index: 2;
			position: relative;
			border-radius: 5px;
			margin-bottom: 4rem;
			&__Contact {
				padding: 6rem 2rem 1rem;
			}
		}
		&__Contact {
			@media only screen and(min-width: $tab-port) {
				padding: 6rem 4rem 1rem;
			}
		}
	}
	&__title {
		color: $color-blue;
		font-size: 6rem;
		text-align: center;
	}
	&__image {
		border-radius: 50%;
		height: 26rem;
		width: 26rem;
		border: 2px solid $color-light;
		margin: 4rem auto 3rem;
		overflow: hidden;
		box-shadow: 0 1rem 2rem rgba($color: #000000, $alpha: 0.8);

		&_pic {
			&__About {
				@include singleComponentImage(
					"../assets/images/meditating-baltimore.jpg"
				);
				-webkit-transform: scale(2);
				transform: scale(2);
				background-position: 66%;

				@media only screen and(min-width: $phone) {
					-webkit-transform: scale(1);
					transform: scale(1);
					background-position: 53% 67%;
				}
			}

			&__Register {
				@include singleComponentImage(
					"../assets/images/Lotus-picture-2000.jpg"
				);
				background-position: 53% 28%;
			}
			&__Resources {
				@include singleComponentImage(
					"../assets/images/rocks-balance-1920.jpg"
				);
				@media only screen and(min-width: $bp-500) {
					background-position: 0 7%;
				}
				@media only screen and(min-width: $bp-700) {
					background-position: 0 15%;
				}
			}
			&__Contact {
				@include singleComponentImage(
					"../assets/images/morning-prayer-large.jpg"
				);
				background-position: 47% 10%;

				@media only screen and(min-width: $bp-500) {
					background-position: 47% 72%;
				}
				@media only screen and(min-width: $bp-850) {
					background-position: 47% 65%;
				}
				@media only screen and(min-width: $bp-1000) {
					background-position: 47% 60%;
				}
			}
		}
		@media only screen and(min-width: $bp-500) {
			border: none;
			border-radius: 0 0 5px 5px;
			width: 100%;
			margin: 0 0 3rem;
			height: 60vh;
		}
	}
}
