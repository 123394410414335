@import "../../../../sass/main";

.Sparkle {
	border-radius: 100%;
	height: 1rem;
	width: 1rem;
	position: absolute;
	background-color: transparent;
	border: 1px dotted $color-light;
	-webkit-animation: sparkle 3s forwards;
	animation: sparkle 3s forwards;

	-webkit-transform: translateX(50%);
	transform: translateX(50%);
}
