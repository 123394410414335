@import "../../../sass/main";

.Resources {
	padding: 4rem;
	display: grid;
	grid-row-gap: 6rem;
	justify-items: center;
	text-align: center;

	@media only screen and(min-width: $bp-500) {
		padding: 4rem 0;
		text-align: left;
	}
	@media only screen and(min-width: $bp-700) {
		padding: 4rem;
		justify-items: start;
	}
	&_ten_bulls {
		margin: 20px 0;
	}
	&_audio {
		&:active, &:focus {
			outline: none;
		}
	}
	&__youtube {
		width: 32rem;
		height: 24rem;
		border-radius: 5px;
		overflow: hidden;
		margin-top: 1rem;
		@media only screen and(min-width: $bp-500) {
			width: 28rem;
			height: 21rem;
			text-align: left;
		}
		@media only screen and(min-width: $phone) {
			width: 32rem;
			height: 24rem;
			text-align: left;
		}
		@media only screen and(min-width: $tab-land) {
			width: 40rem;
			height: 30rem;
			text-align: left;
		}
	}
	&__link {
		&:link,
		&:visited {
			@include link;
		}
		&__YC {
			margin-bottom: 1rem;
		}
	}

	&__YC {
		height: 60vh;
		width: 90%;
		margin-bottom: 5rem;
	}
	&__google_doc {
		height: 100%;
		overflow: scroll;
	}
}
