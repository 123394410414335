@import "../../../../sass/main";

.Card {
	border: 1px solid $color-light;
	@include short-box-shadow;
	margin: 6rem 3rem;
	border-radius: 5px;
	@media only screen and(min-width: $bp-500) {
		margin: 6rem 0;
	}
	@media only screen and(min-width: $bp-1000) {
		width: 30%;
	}

	&__title {
		color: white;
		font-size: 4rem;
		text-align: center;
		padding: 4rem;
		background-size: cover;

		@media only screen and(min-width: $bp-1000) {
			font-size: 2.5rem;
			height: 12rem;
			display: -webkit-box;
			display: flex;
			-webkit-box-align: center;
			align-items: center;
			-webkit-box-pack: center;
			justify-content: center;
		}
		@media only screen and(min-width: $tab-land) {
			font-size: 2.8rem;
		}

		&__Meditation {
			@include filterBackgroundImage(
				$url: "../assets/images/hero640.jpg"
			);
			background-position: 0% 50%;
		}
		&__Yoga {
			@include filterBackgroundImage(
				$url: "../assets/images/yoga-hero-640.jpg"
			);
			background-position: 0% 85%;
		}
		&__Both {
			@include filterBackgroundImage(
				$url: "../assets/images/Lotus-picture.jpg"
			);
			background-position: 0% 40%;
		}
	}
	&__purchase {
	}
}
