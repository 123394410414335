@import "../../../sass/main";

.drawer {
	height: 100%;
	width: 100%;
	position: absolute;
	left: 0;
	z-index: 9;
	-webkit-transition: all 0.4s;
	-webkit-transition: all 1s;
	transition: all 1s;
	font-size: 3rem;
	overflow: hidden;

	@include gradient-background;
	display: grid;
	-webkit-box-align: center;
	align-items: center;
}

.closed {
	top: -100%;
	opacity: 0;
}

.open {
	top: 0;
	opacity: 1;
}
