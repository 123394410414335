@import "./sass/main";

*,
*::after,
*::before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html {
	font-size: 50%;

	@media only screen and(min-width: $bp-500) {
		font-size: 62.5%;
	}
}

body {
	box-sizing: border-box;
	font-family: $font-display;

	@media only screen and(min-width: $tab-port) {
		padding: 3rem;
	}
	@media only screen and(min-width: $tab-land) {
		max-width: 95%;
	}
	// max-width: 120rem;
	margin: 0 auto;
}

.no__scroll {
	overflow: hidden;
}
