@import "../../sass/main";

.nav {
	width: 100%;
	z-index: 10;
	background-color: $color-blue;
	height: 5rem;
	display: -webkit-box;
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	@media only screen and(min-width: $tab-port) {
		display: block;
	}
	&:link,
	&:visited {
		text-decoration: none;
	}
	&__title {
		position: relative;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: 100%;
		height: 100%;
		&__text {
			font-size: 2.6rem;
			color: white; //rgb(228, 174, 0); //rgb(201, 162, 0);
			position: absolute;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			left: 1.5rem;
			text-decoration: none;
			height: 60%;
			font-family: $font-title;
		}
		@media only screen and(min-width: $tab-port) {
			display: none;
		}
	}
	// &__homelink {
	// 	color: white;
	// 	font-size: 2rem;
	// 	text-decoration: none;
	// 	font-family: $font-title;
	// 	// width: ;
	// }
	p {
		height: 0;
	}
	&__links {
		display: grid;
		-webkit-box-align: center;
		align-items: center;
		padding: 0 1.5rem;
		justify-items: end;
		height: 100%;
	}
}
