@import "../../../sass/main";

%heading {
	color: white;
	font-size: 4.3rem;
	line-height: 1;
	font-family: $font-title;
	font-style: italic;
}

.header {
	position: -webkit-sticky;
	position: sticky;
	top: 0rem;
	height: $section-height;
	padding: 1.2rem;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: repeat(3, -webkit-min-content);
	grid-template-rows: repeat(3, min-content);
	grid-row-gap: 0.8rem;
	background-blend-mode: soft-light;

	&__meditation {
		@include header-background("../assets/images/hero1920.jpg");
		@if 90vh / 100vw < 0.93 {
			background-position: 65% 100%;
		} @else if 90vh / 100vw < 1.31687 {
			background-position: 80% 100%;
		} @else {
			background-position: 100% 100%;
		}
	}
	&__yoga {
		@include header-background("../assets/images/yoga-hero-640.jpg");
		background-position: 50% 100%;
	}
}

.heading {
	&__1 {
		@extend %heading;
		grid-column: 1/2;
		-webkit-animation: flipInUp 1s ease-out;
		animation: flipInUp 0.5s ease-out;

		&__yoga {
			font-size: 6rem;
			@media only screen and(min-width: $phone) {
				font-size: 10rem;
			}
			@media only screen and(min-width: $tab-port) {
				font-size: 12rem;
			}
		}
	}
	&__2 {
		@extend %heading;
		grid-column: 1/2;
		-webkit-animation: appear 0.5s;
		animation: appear 0.5s;
		-webkit-animation: flipInUp 0.5s ease-out 0.5s backwards;
		animation: flipInUp 0.5s ease-out 0.5s backwards;
		&__meditation {
			font-size: 6rem;
			@media only screen and(min-width: $phone) {
				font-size: 7rem;
			}
			@media only screen and(min-width: $tab-port) {
				font-size: 9rem;
			}
		}
	}
	&__3 {
		@extend %heading;
		grid-column: 1/2;
		// color: white;
		-webkit-animation: flipInUp 0.5s ease-out 1s backwards;
		animation: moveInRight 0.5s ease-out 1s backwards;
	}
}
