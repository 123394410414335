@import "../../../../../sass/main";

.PassLinks {
	padding: 1rem;
	font-size: 2.3rem;
	@media only screen and(min-width: $bp-1000) {
		font-size: 1.8rem;
	}
	@media only screen and(min-width: $tab-land) {
		font-size: 2.1rem;
	}
	&__link {
		color: $color-blue;
		width: -webkit-max-content;
		width: -moz-max-content;
		width: max-content;
		display: block;
		text-align: center;
		cursor: pointer;
		margin: 3rem auto;
		&:after {
			@include after-underline;
		}

		&:hover {
			@include hover-shift;
		}
	}
}
