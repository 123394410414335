@import "../../../sass/main";
.About {
	padding: 3rem;
	@media only screen and(min-width: $bp-500) {
		padding: 3rem 0;
	}
	&__text {
		padding: 2rem 6rem 3rem;
		@media only screen and(min-width: $bp-500) {
			padding: 2rem 0 0;
		}
		&_p {
			color: $color-blue;
			font-size: 2.5rem;
			margin-bottom: 2rem;
			font-weight: bold;
		}
	}
}
